.container-coucou-visio {
  .mat-mdc-snack-bar-container {
    @apply my-6;

    .mdc-snackbar__surface {
      @apply rounded-2xl bg-white;
      box-shadow: 0 8px 32px 0 rgba(31, 35, 46, 0.15);
      padding-right: 0;

      .mdc-snackbar__label {
        @apply text-gray-500;
      }
    }
  }
}
