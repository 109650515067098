@layer components {
  .menu-wrapper {
    @apply sticky top-0 z-50 bg-white;

    @apply flex items-center p-6 lg:py-4 lg:px-8 mb-6;

    .container-action, .container-logo, .container-profile {
      @apply flex-1;
    }

    .container-logo {
      @apply flex justify-center;
    }

    .container-profile {
      @apply flex justify-end;

      .content {
        @apply flex items-center gap-3 w-fit rounded-3xl lg:py-2 lg:px-3;

        &:hover {
          @apply cursor-pointer bg-gray-100;
        }

        .info {
          @apply hidden lg:block lg:max-w-56;

          .name, .email {
            @apply whitespace-nowrap overflow-hidden text-ellipsis leading-4;
          }

          .name {
            @apply text-base font-medium text-gray-900;
          }

          .email {
            @apply text-sm text-gray-500;
          }
        }

      }
    }
  }
}
