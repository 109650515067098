@layer components {
  app-authentication-template {

    .logo {
      @apply flex justify-center mb-12;
    }

    .auth-page-header {
      @apply text-center;
    }

    .content {
      @apply mb-10;
    }

    .container-link {
      @apply flex items-center gap-3;

      .text-new {
        @apply hidden md:flex text-gray-400;
      }
    }

    form {
      button {
        @apply mt-4;
      }
    }

    .container-forgot-link {
      @apply mt-4;

      a {
        @apply text-primary no-underline hover:underline font-medium;
      }
    }

  }
}
