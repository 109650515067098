.container-coucou-visio {

  .dialog-container {
    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        @apply md:rounded-2xl;
      }
    }

    .container-header-modal {
      @apply flex items-center justify-between absolute top-2.5 right-5 left-3;
    }

    &.size-m {
      @apply w-full h-full md:h-auto md:w-4/5 lg:w-[33.75rem];
      @apply max-w-full #{!important};
    }

    &.size-lg {
      @apply w-full h-full md:h-auto md:w-4/5 lg:w-[45.75rem];
      @apply max-w-full #{!important};
    }

    .container-close-button {
      @apply absolute top-0 right-0 p-6;

      img {
        @apply cursor-pointer;
      }
    }

    .container-modal-content {
      @apply text-center text-gray-500 px-6 pb-6;
    }

    .container-file-uploaded {
      @apply flex items-center justify-between h-12 border border-gray-300 rounded-xl px-4 mt-3;

      .cancel-upload {
        @apply flex items-center justify-center bg-error-100 h-6 w-6 rounded-full cursor-pointer;
      }
    }

    .container-modal-form {
      @apply md:max-h-[24rem] 2xl:max-h-[calc(100vh-25rem)] overflow-y-auto pl-6 pr-6 md:pt-6 md:pb-12;
    }

    .container-modal-actions {
      @apply flex flex-col md:flex-row items-center gap-3 md:bg-gray-100 py-4 px-6;
    }
  }

}
