.container-coucou-visio {

  pagination-controls.admin-pagination-table {
    @apply flex justify-center mt-10;

    .ngx-pagination {
      @apply flex items-center gap-4;

      li {
        @apply rounded-lg h-10 w-10;

        &:not(.small-screen){
          @apply flex items-center justify-center;
        }

        &:not(.current):not(.disabled){
          @apply bg-white;
        }

        &.pagination-previous {
          &::before {
            margin: 0;
          }

          a::before {
            margin: 0;
          }
        }

        &.pagination-next {
          a::after {
            margin: 0;
          }

          &.disabled::after {
            margin: 0;
          }
        }

        &.current {
          @apply text-white bg-secondary;
        }

        a {
          @apply text-gray-500 text-sm font-semibold no-underline hover:bg-transparent;
        }
      }
    }

  }
}
