@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use "assets/styles/components/theme" as *;
@use "assets/styles/components/button" as *;
@use "assets/styles/components/table" as *;
@use "assets/styles/components/dialog" as *;
@use "assets/styles/components/form" as *;
@use "assets/styles/components/chips" as *;
@use "assets/styles/components/spinner" as *;
@use "assets/styles/components/menu" as *;
@use "assets/styles/components/pagination" as *;
@use "assets/styles/components/snack-bar" as *;

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'assets/styles/tailwind/font';
@import 'assets/styles/tailwind/onboarding';
@import 'assets/styles/tailwind/authentifcation';
@import 'assets/styles/tailwind/error';
@import 'assets/styles/tailwind/section';
@import 'assets/styles/tailwind/visio';
@import 'assets/styles/tailwind/menu';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$coucou-client-primary: mat.define-palette($coucou-primary-palette);
$coucou-client-accent: mat.define-palette($coucou-secondary-palette);

// The warn palette is optional (defaults to red).
$coucou-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$coucou-client-theme: mat.define-light-theme((
  color: (
    primary: $coucou-client-primary,
    accent: $coucou-client-accent,
    warn: $coucou-client-warn,
  ),
  typography: mat.define-typography-config(
    $font-family: 'Inter, sans serif'
  ),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($coucou-client-theme);

html, body {
  @apply h-full;
}

body {
  @apply m-0 bg-white text-sm;
  font-family: Inter, sans-serif;
}

.full-width {
  @apply w-full;
}

//TODO: Fix why Tailwind add border right
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-input-element {
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-size-adjust: inherit;
  transition: background-color 5000s ease-in-out 0s;
}
