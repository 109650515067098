.container-coucou-visio {

  .cdk-overlay-pane {
    .mat-mdc-menu-panel {
      @apply rounded-2xl max-w-lg;
      box-shadow: 0 8px 32px 0 rgba(31, 35, 46, 0.15);

      .mat-mdc-menu-content {
        @apply p-4 rounded-3xl;

        .mat-mdc-menu-item {
          @apply rounded-lg py-2.5 px-4;

          .mat-mdc-menu-item-text {
            @apply flex items-center gap-3 text-gray-900;
          }

          &.warn {
            .mat-mdc-menu-item-text {
              @apply text-error;
            }
          }
        }
      }

      .divider {
        @apply w-full h-px bg-gray-300 my-3;
      }
    }

    .container-menu-helper {
      @apply shadow-none bg-transparent mb-4;

      .mat-mdc-menu-content {
        @apply flex flex-col items-end gap-4 py-0.5 px-0;
      }
    }

    .mat-mdc-select-panel {
      @apply p-4 rounded-3xl;
      box-shadow: 0 8px 32px 0 rgba(31, 35, 46, 0.15);

      .mat-mdc-option {
        @apply rounded-xl;
      }
    }
  }
}
