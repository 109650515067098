.container-coucou-visio {

  table.admin-table, table.connected-table {
    @apply border-separate border-spacing-y-3 bg-transparent;
    width: 100%;
    table-layout: fixed;

    .mat-mdc-header-cell, .mat-mdc-cell {
      @apply border-none;
    }

    .mat-mdc-header-row, .mat-mdc-row {
      .mat-mdc-header-cell, .mat-mdc-cell {
        &:first-of-type {
          @apply rounded-s-xl;
        }

        &:last-of-type {
          @apply rounded-e-xl;
        }
      }
    }

    .mat-mdc-header-row {
      @apply h-12;

      .mat-mdc-header-cell {
        @apply text-sm font-normal text-gray-400 uppercase;

        &.column-actions {
          @apply w-56 text-center;
        }
      }
    }

    .mat-mdc-row {
      @apply h-16 overflow-hidden text-ellipsis whitespace-nowrap;

      .mat-mdc-cell {
        @apply text-sm font-medium text-gray-500;

        .container-state {
          @apply flex items-center gap-2;

          &::before {
            @apply content-[''] h-2 w-2 rounded-full;
          }

          &.active, &.success {
            @apply text-success before:bg-success;
          }

          &.inactive {
            @apply text-gray-400 before:bg-gray-400;
          }

          &.warning {
            @apply text-warning before:bg-warning;
          }

          &.error {
            @apply text-error before:bg-error;
          }
        }

        .container-role {
          @apply flex items-center w-fit rounded-full px-3 py-2;

          &.role_client_admin {
            @apply bg-secondary-100 text-secondary;
          }

          &.role_installer {
            @apply bg-primary-100 text-primary;
          }
        }

        .container-actions-menu {
          @apply flex items-center gap-2 w-fit rounded-lg px-3 py-2 hover:bg-gray-100 cursor-pointer text-primary;
        }

        .container-cell-actions {
          @apply flex items-center justify-center gap-2;

          div {
            @apply h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer hover:bg-gray-100;
          }
        }
      }
    }
  }

  table.admin-table {
    .mat-mdc-header-cell, .mat-mdc-cell {
      @apply bg-white;
    }
  }

  table.connected-table {
    .mat-mdc-header-cell, .mat-mdc-cell {
      @apply bg-gray-100;

      .container-member-role {
        @apply flex items-center w-fit gap-1 pl-3 pr-4 py-2 rounded-full;

        &.member {
          @apply bg-primary-100 text-primary;
        }

        &.admin {
          @apply bg-secondary-100 text-secondary;
        }
      }
    }
  }

  .container-empty-table {
    @apply flex items-center justify-center bg-white h-24 text-base italic text-gray-400 rounded-xl;
  }

}
