#connected-call-page,
#remote-call-page {
  @apply h-full w-full relative;

  &.video-call {
    .container-logo {
      @apply absolute top-4 left-4 md:top-8 md:left-8 z-50;
      @apply transition-all duration-300 ease-in-out;

      img {
        @apply h-auto w-32;
      }
    }

    .container-tracks {
      @apply absolute top-0 bottom-0 right-0 left-0 bg-black;

      #desktop-tracks {
        display: none;
      }

      .container-users-videos {
        @apply w-full h-full;
        @apply transition-all duration-300 ease-in-out;

        #remote-tracks {
          @apply w-full h-full;

          video {
            @apply max-w-full h-full m-auto;
          }
        }

        #local-tracks {
          @apply absolute z-50;

          &.position-default {
            @apply bottom-8 right-8 w-72 h-44;
          }

          &.position-handset-portrait {
            @apply bottom-2 right-2 w-20 h-36;
          }

          &.position-handset-landscape {
            @apply top-2 right-2 w-36 h-20;
          }

          video#local-video {
            @apply object-cover absolute right-0 left-0 top-0 bottom-0;
          }
        }
      }
    }
  }

  &.screen-share {
    .container-logo {
      @apply absolute top-4 right-4 md:top-8 md:right-8 z-50;
      @apply transition-all duration-300 ease-in-out;

      img {
        @apply h-auto w-32;
      }
    }

    .screen-share-message {
      @apply absolute top-[2%] w-full font-semibold text-lg text-white z-50;
      @apply flex justify-center;
    }

    .container-tracks {
      @apply absolute top-0 bottom-0 right-0 left-0 bg-black;
      @apply flex items-center gap-2;

      .container-desktop-screen {
        @apply flex-1;
        @apply transition-all duration-300 ease-in-out;
      }

      .container-users-videos {
        @apply w-1/5 flex flex-col gap-4;
        @apply transition-all duration-300 ease-in-out;
      }
    }
  }
}
