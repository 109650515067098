.container-coucou-visio {

  .mat-mdc-unelevated-button, .mat-mdc-outlined-button {
    @apply rounded-full tracking-normal font-medium;

    &.mat-mdc-button-base {
      @apply h-11;
    }

    &.size-xs {
      &.mat-mdc-button-base {
        @apply h-10;
      }
    }

    &.size-xl {
      &.mat-mdc-button-base {
        @apply h-14 text-base;
      }
    }

    .icon {
      @apply flex items-center gap-2;
    }
  }

  .mat-mdc-unelevated-button {
    &.mat-accent {
      @apply text-white;
    }
  }

  .mat-mdc-outlined-button {
    @apply bg-white;

    &.secondary-button {
      @apply w-fit border-secondary;
    }

    &.btn-stroked-primary {
      @apply border-primary text-base;
    }

    &.btn-upload {
      @apply border-primary text-sm;
    }
  }

  .mat-mdc-fab {
    @apply shadow-none hover:shadow-none;
  }

  .mat-mdc-checkbox {
    &.mat-accent {
      .mdc-checkbox {
        .mdc-checkbox__background {
          .mdc-checkbox__checkmark {
            @apply text-white;
          }
        }
      }
    }
  }
}
