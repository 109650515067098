@layer components {

  h1 {
    @apply text-2xl md:text-4xl text-primary font-bold mb-2;
  }

  h4 {
    @apply text-lg font-medium text-gray-900 mb-2
  }

  a {
    @apply text-sm font-medium text-secondary no-underline hover:underline;
  }

  .content {
    @apply text-base text-gray;
  }

  .content-grey-300 {
    @apply text-base text-gray-400 mb-8;
  }
}
