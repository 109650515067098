.container-coucou-visio {

  mat-chip {
    @apply rounded-xl;
    border-radius: 10px;

    &.success {
      @apply bg-success-100;

      .chip-content {
        @apply text-success-700;
      }
    }

    &.warning {
      @apply bg-warning-100;

      .chip-content {
        @apply text-warning-700;
      }
    }

    &.info {
      @apply bg-info-100;

      .chip-content {
        @apply text-info-700;
      }
    }

    .chip-content {
      @apply flex items-end font-medium;
    }
  }

}
