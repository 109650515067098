@layer components {

  #onboarding-profile-page,
  #onboarding-address-page,
  #onboarding-subscription-page,
  #onboarding-summary-page,
  #onboarding-member-page {

    .container-actions {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background-color: white;
    }
  }

  #onboarding-payment-page {
    .container-state-img {
      @apply flex justify-center mb-6;
    }

    .container-actions {
      @apply flex flex-col-reverse md:flex-row items-center gap-4 md:gap-6 mt-10;
    }
  }
}
