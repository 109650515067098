.container-coucou-visio {

  input {
    &:read-only {
      @apply cursor-default;
    }
  }

  form, .form {

    .form-container-title {
      @apply text-gray font-medium mb-2;
    }

    .container-input-inline {
      @apply flex flex-col md:flex-row gap-0 md:gap-4;
    }

    .row {
      @apply flex justify-center gap-6;

      mat-form-field {
        @apply flex-1;
      }
    }

    mat-form-field {
      @apply w-full text-sm mb-6;

      &.password-helper {
        @apply mb-2;
      }

      mat-select {
        @apply text-sm;
      }

      .mdc-text-field {
        @apply rounded-xl;
      }

      .mat-mdc-text-field-wrapper {
        @apply bg-gray-100 flex-none;

        .mdc-notched-outline {
          .mdc-notched-outline__leading {
            @apply rounded-l-xl border-gray-300;
          }

          .mdc-notched-outline__notch {
            @apply border-gray-300;
          }

          .mdc-notched-outline__trailing {
            @apply rounded-r-xl border-gray-300;
          }
        }

        textarea {
          @apply resize-none w-full h-28;
        }

        .mat-mdc-form-field-icon-suffix {
          @apply pr-4 cursor-pointer;
        }
      }
    }

    .otp-input-container {
      mat-form-field {

        .mat-mdc-text-field-wrapper {
          @apply flex items-center;

          input {
            @apply text-center text-3xl md:text-5xl font-bold text-gray-900;
          }
        }

        .mat-mdc-form-field-subscript-wrapper {
          @apply hidden;
        }
      }
    }
  }

  .search-input-component {
    mat-form-field {
      @apply w-full mb-1.5;

      .mdc-text-field {
        @apply rounded-xl;
      }

      .mat-mdc-text-field-wrapper {
        @apply bg-white;

        .mdc-notched-outline {
          .mdc-notched-outline__leading {
            @apply rounded-l-xl border-gray-300;
          }

          .mdc-notched-outline__notch {
            @apply border-gray-300;
          }

          .mdc-notched-outline__trailing {
            @apply rounded-r-xl border-gray-300;
          }
        }

        .mat-mdc-form-field-icon-suffix {
          @apply pl-1 pr-4;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }
  }
}
