@layer components {
  .container-installation-guide {
    @apply w-full;

    .installation-guide-step, .container-installation-validation {
      @apply flex items-center flex-col md:flex-row gap-6 md:gap-24 mb-12 md:mb-24;

      iframe {
        @apply w-full md:flex-1 h-72 rounded-3xl;
      }

      .guide-step-content {
        @apply flex-1;

        .step-content-icon {
          @apply flex items-center justify-center h-14 w-14 border-8 border-secondary-50 rounded-full mb-3;

          span {
            @apply flex items-center justify-center h-10 w-10 bg-secondary-100 rounded-full;
          }
        }

        .step-content-title {
          @apply text-xl lg:text-4xl text-gray-900 font-bold mb-4;
        }

        .step-content-content {
          @apply text-sm leading-6 text-gray-500;
        }

        .step-content-list {
          @apply flex flex-col gap-3 mt-4 ml-4;

          .content-list-item {
            @apply flex items-center gap-3;

            div {
              &:first-of-type {
                @apply flex items-center justify-center font-semibold h-7 w-7 rounded-full bg-secondary-100 text-secondary;
              }

              &:last-of-type {
                @apply text-gray-500;
              }
            }

          }
        }
      }
    }
  }
}
