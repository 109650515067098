@layer components {

  .container-error-block {
    @apply w-full bg-error-100 font-medium text-error text-left py-3 px-4 rounded-xl mb-6;
  }

  .container-success-block {
    @apply flex items-center gap-1 w-full bg-success-100 font-medium text-success text-left py-3 px-4 rounded-xl mb-6;
  }

}
